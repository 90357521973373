import Of1 from "../assets/office/office_1.jpg"
import Of2 from "../assets/office/office_2.jpg"
import Of3 from "../assets/office/office_3.jpg"
import Of4 from "../assets/office/office_4.jpg"
import Of5 from "../assets/office/office_5.jpg"
import Of6 from "../assets/office/office_6.jpg"
import Of7 from "../assets/office/office_7.jpg"
import Of8 from "../assets/office/office_8.jpg"
import Of9 from "../assets/office/office_9.jpg"
import Of10 from "../assets/office/office_10.jpg"
import Of11 from "../assets/office/office_11.jpg"
import Of12 from "../assets/office/office_12.jpg"
import Of13 from "../assets/office/office_13.jpg"
import Of14 from "../assets/office/office_14.jpg"


export const officeImages = [
    {
        original: Of1,
        thumbnail: Of1,
    },
    {
        original: Of2,
        thumbnail: Of2,
    },{
        original: Of3,
        thumbnail: Of3,
    },{
        original: Of4,
        thumbnail: Of4,
    },{
        original: Of5,
        thumbnail: Of5,
    },{
        original: Of6,
        thumbnail: Of6,
    },{
        original: Of7,
        thumbnail: Of7,
    },{
        original: Of8,
        thumbnail: Of8,
    },{
        original: Of9,
        thumbnail: Of9,
    },{
        original: Of10,
        thumbnail: Of10,
    },{
        original: Of11,
        thumbnail: Of11,
    },{
        original: Of12,
        thumbnail: Of12,
    },{
        original: Of13,
        thumbnail: Of13,
    },{
        original: Of14,
        thumbnail: Of14,
    },
]