import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { MyMenu, MyMenuItem, MySubMenuItem } from "./styled";
import { data } from "../../data/products";
import "./menu.css"

const CommonMenu = ({ menuMode }) => {
  const history = useHistory();

  const handleClick = (path) => {
    history.push(path);
  };

  return (
    <MyMenu mode={menuMode}>
      {data.map((item, index) => {
        return (
          <Fragment key={`${index}`}>
            {item.subSeries ? (
              <MySubMenuItem title={item.name}>
                {item.subSeries.map((ele, i) => (
                  <MyMenuItem
                    key={`${ele.name}_${i}`}
                    onClick={() => handleClick(`${ele.id}`)}
                  >
                    {ele.name}
                  </MyMenuItem>
                ))}
              </MySubMenuItem>
            ) : (
              <MyMenuItem onClick={() => handleClick(`${item.id}`)}>
                {item.name}
              </MyMenuItem>
            )}
          </Fragment>
        );
      })}
    </MyMenu>
  );
};

export default CommonMenu;
