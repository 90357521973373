import styled, { css } from "styled-components";
import { fontSizes, black } from "../../styles/mixins";
import { mediaPhone } from "../../styles/media";

export const Title = styled.h2`
  font-size: ${fontSizes.large5};
  color: ${({ $color }) => $color || `${black}`};
  ${mediaPhone(css`
    font-size: ${fontSizes.large1};
  `)};
`;
