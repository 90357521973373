export const collections = [
  {
    name: "Ti K SP ESP Color",
    url: "https://www.youtube.com/watch?v=XbdFeUH2e2c&feature=youtu.be",
  },
  {
    name: "Ti K SP105 SP110",
    url: "https://www.youtube.com/watch?v=zbDrXIjVljM&feature=youtu.be",
  },
  {
    name: "Ti-K ES Series",
    url: "https://www.youtube.com/watch?v=Ul7JS_Qi8tM&feature=youtu.be",
  },
  {
    name: "Ti-K AS Series",
    url: "https://www.youtube.com/watch?v=vUuYckZ4LSU&feature=youtu.be",
  },
];
