import styled, { css } from "styled-components";
import { spaces } from "../../styles/mixins";
import { mediaTabPort, mediaPhone } from "../../styles/media";

export const Container = styled.div`
  padding-bottom: ${spaces.large4};
  display: flex;
  background-color: #fff;
  ${mediaTabPort(css`
    flex-direction: column;
  `)}
`;

export const SideMenu = styled.div`
  width: 20%;
  margin-right: ${spaces.large4};
  ${mediaTabPort(css`
    width: 100%;
    margin: 0;
  `)}
`;

export const DetailContainer = styled.div`
  width: 100%;
  padding: ${spaces.large4};
  ${mediaPhone(css`
    padding: ${spaces.large1};
  `)}
`;
