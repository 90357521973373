import ReactPlayer from "react-player/lazy";
import { Container,VideoContainer } from "./styled";

const VideoCard = ({ name, url, ...otherProps }) => {
  return (
    <Container>
      <ReactPlayer
        url={url}
        {...otherProps}
        controls={true}
        wrapper={VideoContainer}
      />
      <span style={{ color: "#fff" }}>{name}</span>
    </Container>
  );
};

export default VideoCard;
