import { useTranslation } from "react-i18next";

import { Container, Header } from "./styled";
const TechCard = ({ title, info }) => {
  const { t } = useTranslation();

  return (<Container>
      <Header>{t(title)}</Header>
      <br/>
        <p>{t(info)}</p>
  </Container>);
};
export default TechCard;
