import { useState, Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import LinkButton from "../../LinkButton";
import {
  StyledBurger,
  Options,
  Navigator,
  LanguageContainer,
  Lang,
} from "./styled";

const Burger = () => {
  const [open, setOpen] = useState(false);
  const isTabPort = useMediaQuery({ query: "(max-width: 900px)" });
  const { t, i18n } = useTranslation();
  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Fragment key='nav'>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <Options open={open}>
        <li>
          <Navigator to='/'>{t("nav.home")}</Navigator>
        </li>
        <li>
          <Navigator to='/products'>{t("nav.product")}</Navigator>
        </li>
        <li>
          <Navigator to='/technology'>{t("nav.technology")}</Navigator>
        </li>
        <li>
          <Navigator to='/about'>{t("nav.about")}</Navigator>
        </li>
        <li>
          <LinkButton toElement='contact'>{t("nav.contact")}</LinkButton>
        </li>
        {isTabPort && (
          <li>
            <Lang onClick={() => handleChangeLanguage("en")}>EN</Lang>
            <Lang onClick={() => handleChangeLanguage("zh-hk")}>中文</Lang>
          </li>
        )}
      </Options>
      {isTabPort ? null : (
        <LanguageContainer>
          <Lang onClick={() => handleChangeLanguage("en")}>EN</Lang>
          <Lang onClick={() => handleChangeLanguage("zh-hk")}>中文</Lang>
        </LanguageContainer>
      )}
    </Fragment>
  );
};
export default Burger;
