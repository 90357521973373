import { createGlobalStyle } from "styled-components";
import { fontSizes, fontWeight, white, black } from "./mixins";
const GlobalStyle = createGlobalStyle`
    *,
    *::after,
    *::before {
        margin: 0;
        padding: 0;
        box-sizing: inherit;
    }

    html{
        font-size: 62.5%;
    }

    body {
        box-sizing: border-box;
        font-family: "Montserrat", sans-serif;
        font-weight: ${fontWeight.regular};
        font-size: ${fontSizes.normal};
        color: ${black};
        background-color: ${white};
    }

    h1, h2, h3, h4, h5, h6, button {
        font-weight: ${fontWeight.regular};
    }

    a:link,a:visited {
        cursor: pointer;
        text-decoration: none;
    }

    p, div, a, span {
        word-break: break-word;
    }

    button {
        word-break: initial;
    }
`;

export default GlobalStyle;
