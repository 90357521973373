import styled, { css } from "styled-components";
import { spaces, fontSizes } from "../../styles/mixins";
import { centerCrop } from "../../styles/image";
import { mediaTabPort, mediaPhone } from "../../styles/media";

export const Container = styled.div`
  width: ${({ $width }) => $width || "150px"};
  height: ${({ $height }) => $height || "200px"};
  padding: ${spaces.normal};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${mediaPhone(css`
    margin: 0 auto;
  `)}
`;

export const Img = styled.img`
  width: ${({ $size }) => $size || "70%"};
  margin: 0 auto ${spaces.large4};
  ${centerCrop}
`;

export const Describe = styled.span`
  font-size: ${fontSizes.large1};
  ${mediaTabPort(css`
    font-size: ${fontSizes.normal};
  `)}
  ${mediaPhone(css`
    font-size: ${fontSizes.small2};
  `)}
`;
