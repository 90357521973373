export const data = [
  {
    name: "AS series",
    id: "as",
    inci: "Triethoxycarprylyl silane",
    product: [
      "Ti-K Talc SF AS",
      "Ti-K Talc 13R AS",
      "Ti-K Talc 46R AS",
      "Ti-K TiO2 407 AS",
      "Ti-K TiO2 CR50 AS",
      "Ti-K ZnO AS",
      "Ti-K Sericite M AS",
      "Ti-K Sericite FSE AS",
      "Ti-K Mica AS",
      "Ti-K Iron Oxide AS",
    ],
    skinFeel: "product.skin.as",
    waterRepel: "product.water.as",
    color: "product.color.as",
    performance: "product.performance.as",
    recommend: ["EM", "LQ", "P", "PM"],
    dose: "10-80%",
    video: "https://www.youtube.com/watch?v=vUuYckZ4LSU&feature=youtu.be",
  },
  {
    name: "ES series",
    id: "es",
    inci: "Glyceryl/Polyglyceryl-6 isostearate/Behenate Ester",
    product: [
      "Ti-K Talc SF ES",
      "Ti-K Talc 13R ES",
      "Ti-K Talc 46R ES",
      "Ti-K TiO2 407 ES",
      "Ti-K TiO2 CR50 ES",
      "Ti-K ZnO ES",
      "Ti-K Sericite M ES",
      "Ti-K Sericite FSE ES",
      "Ti-K Mica ES",
      "Ti-K Iron Oxide ES",
    ],
    skinFeel: "product.skin.es",
    waterRepel: "product.water.es",
    color: "product.color.es",
    performance: "product.performance.es",
    recommend: ["EM", "LQ", "P", "PM"],
    dose: "10-80%",
    video: "https://www.youtube.com/watch?v=Ul7JS_Qi8tM&feature=youtu.be",
  },
  {
    name: "MS series",
    id: "ms",
    inci: "Methicone",
    product: [
      "Ti-K Talc SF MS",
      "Ti-K Talc 13R MS",
      "Ti-K Talc 46R MS",
      "Ti-K TiO2 407 MS",
      "Ti-K TiO2 CR50 MS",
      "Ti-K ZnO MS",
      "Ti-K Sericite M MS",
      "Ti-K Sericite FSE MS",
      "Ti-K Sericite K MS",
      "Ti-K SPG 10 MS",
      "Ti-K Silica MS",
    ],
    skinFeel: "product.skin.ms",
    waterRepel: "product.water.ms",
    color: "product.color.ms",
    performance: "product.performance.ms",
    recommend: ["LQ", "P", "PM"],
    dose: "10-80%",
  },
  {
    name: "DS series",
    id: "ds",
    inci: "Dimethicone",
    product: [
      "Ti-K Talc SF DS",
      "Ti-K Talc 13R DS",
      "Ti-K Talc 46R DS",
      "Ti-K TiO2 407 DS",
      "Ti-K TiO2 CR50 DS",
      "Ti-K ZnO DS",
      "Ti-K Sericite M DS",
      "Ti-K Sericite FSE DS",
      "Ti-K Mica DS",
      "Ti-K Silica DS",
    ],
    skinFeel: "product.skin.ds",
    waterRepel: "product.water.ds",
    color: "product.color.ds",
    performance: "product.performance.ds",
    recommend: ["LQ", "P", "PM"],
    dose: "10-80%",
  },
  {
    name: "HDS series",
    id: "hds",
    inci: "Hydrogen	Dimethicone",
    product: [
      "Ti-K Talc SF HDS",
      "Ti-K Talc 13R HDS",
      "Ti-K Talc 46R HDS",
      "Ti-K TiO2 407 HDS",
      "Ti-K TiO2 CR50 HDS",
      "Ti-K ZnO HDS",
      "Ti-K Sericite M HDS",
      "Ti-K Sericite FSE HDS",
    ],
    skinFeel: "product.skin.hds",
    waterRepel: "product.water.hds",
    color: "product.color.hds",
    performance: "product.performance.hds",
    recommend: ["LQ", "P"],
    dose: "10-80%",
  },
  {
    name: "AS/CW series",
    id: "cw",
    inci: "Triethoxycaprylylsilane/Bis‐PEG‐12 Dimethicone/Candelilla wax",
    product: [
      "Ti-K Talc SF AS/CW",
      "Ti-K Talc 13R AS/CW",
      "Ti-K Talc 46R AS/CW",
      "Ti-K TiO2 407 AS/CW",
      "Ti-K TiO2 CR50 AS/CW",
      "Ti-K ZnO AS/CW",
      "Ti-K Sericite M AS/CW",
      "Ti-K Sericite FSE AS/CW",
    ],
    skinFeel: "product.skin.ascw",
    waterRepel: "product.water.ascw",
    color: "product.color.ascw",
    performance: "product.performance.ascw",
    recommend: ["LQ", "P"],
    dose: "10-80%",
  },
  {
    name: "GS series",
    id: "gs",
    inci: "Acrylates/Perfluorohexylethyl Methacrylate copolymer",
    product: [
      "Ti-K Talc SF GS",
      "Ti-K Talc 13R GS",
      "Ti-K Talc 46R GS",
      "Ti-K TiO2 407 GS",
      "Ti-K TiO2 CR50 GS",
      "Ti-K ZnO GS",
      "Ti-K Sericite M GS",
      "Ti-K Sericite FSE GS",
      "Ti-K Mica GS",
      "Ti-K Iron Oxide GS",
    ],
    skinFeel: "product.skin.gs",
    waterRepel: "product.water.gs",
    oilRepel: "product.oil.gs",
    color: "product.color.gs",
    performance: "product.performance.gs",
    recommend: ["LQ", "P"],
    dose: "10-80%",
  },
  {
    name: "Lusil series",
    id: "lusil",
    inci: "Cyclopentasiloxane/DImethicone/Vinyl	Dimethicone	Crosspolymer",
    product: [
      "Ti-K Lusil Talc SF",
      "Ti-K Lusil Talc 13R",
      "Ti-K Lusil Talc 46R",
      "Ti-K Lusil TiO2 407",
      "Ti-K Lusil TiO2 CR50",
      "Ti-K Lusil ZnO",
      "Ti-K Lusil Sericite M",
      "Ti-K Lusil Sericite FSE",
      "Ti-K Lusil Mica",
    ],
    skinFeel: "product.skin.lusil",
    waterRepel: "product.water.lusil",
    color: "product.color.lusil",
    performance: "product.performance.lusil",
    recommend: ["EM", "LQ", "P", "PM"],
    dose: "10-80%",
  },
  {
    name: "LA series",
    id: "la",
    inci:
      "Butyl Methoxydibenzoylmethane/ Triethoxycaprylylsilane/	Hydrogenated Lecithin/ Tocopherol	Acetate",
    product: ["Ti-K Talc SF LA", "Ti-K Sericite M LA"],
    skinFeel: "product.skin.la",
    waterRepel: "product.water.la",
    color: "product.color.la",
    performance: "product.performance.la",
    recommend: ["LQ", "P"],
    dose: "10-80%",
    special: "product.special.la",
  },
  {
    name: "LO series",
    id: "lo",
    inci:
      "Ethylhexyl Methoxydibenzoylmethane/ Triethoxycaprylylsilane/	Hydrogenated Lecithin/ Tocopherol	Acetate",
    product: ["Ti-K Talc SF LO", "Ti-K Sericite M LO"],
    skinFeel: "product.skin.lo",
    waterRepel: "product.water.lo",
    color: "product.color.lo",
    performance: "product.performance.lo",
    recommend: ["LQ", "P"],
    dose: "10-80%",
    special: "product.special.lo",
  },
  {
    name: "DAS series",
    id: "das",
    inci: "Dimethicone/Triethoxy caprylylsilane",
    product: ["Ti-K Talc SF DAS", "Ti-K Talc 13R DAS"],
    skinFeel: "product.skin.das",
    waterRepel: "product.water.das",
    color: "product.color.das",
    performance: "product.performance.das",
    recommend: ["PM", "LQ", "P"],
    dose: "10-80%",
  },
  {
    name: "DMM series",
    id: "dmm",
    inci: "Dimethicone/Methicone",
    product: ["Ti-K Sericite FSE DMM"],
    skinFeel: "product.skin.dmm",
    waterRepel: "product.water.dmm",
    color: "product.color.dmm",
    performance: "product.performance.dmm",
    recommend: ["PM", "LQ", "P"],
  },
  {
    name: "SP series",
    subSeries: [
      {
        name: "Ti‐K SP110 / Ti-K SP105",
        id: "sp110",
        inci: "Organopolysiloxane",
        skinFeel: "product.skin.sp110",
        performance: "product.performance.sp110",
        recommend: ["EM", "LQ", "P", "PM", "LS"],
        video: "https://www.youtube.com/watch?v=zbDrXIjVljM&feature=youtu.be",
      },
      {
        name: "Ti‐K SP Titan",
        id: "sp-titan",
        inci:
          "Dimethicone/Vinyl Dimethicone Crosspolymer, Titanium	Dioxide, Triethoxycaprylylsilane, Alunimum Hydroxide ",
        skinFeel: "product.skin.titan",
        performance: "product.performance.titan",
        recommend: ["LQ", "P"],
      },
      {
        name: "Ti‐K SP Talc",
        id: "sp-talc",
        inci:
          "Dimethicone/Vinyl Dimethicone Crosspolymer, Talc, Triethoxycaprylylsilane",
        skinFeel: "product.skin.titan",
        performance: "product.performance.titan",
        recommend: ["EM", "LQ", "P", "PM", "LS"],
      },
    ],
  },
];
