import { useTranslation } from "react-i18next";
import { Container, Img, Describe } from "./styled";

const IconCard = ({ src, alt, describe,width,height,imgSize}) => {
  const { t } = useTranslation();

  return (
    <Container $width={width} $height={height}>
      <Img src={src} alt={alt} $size={imgSize} />
      <Describe>{t(describe)}</Describe>
    </Container>
  );
};
export default IconCard;
