import styled, { css } from "styled-components";
import { Element } from "react-scroll";
import { mediaTabLand, mediaTabPort, mediaPhone } from "../../styles/media";
import {
  white,
  spaces,
  fontSizes,
  fontWeight,
  otherColor,
  black,
} from "../../styles/mixins";

export const QuoteContainer = styled.div`
  color: ${white};
  position: absolute;
  width: 60%;
  display: flex;
  flex-direction: column;
  top: 40%;
  right: ${spaces.large4};
  transform: translateY(-40%);
  align-items: flex-end;
  text-align: right;

  ${mediaTabPort(css`
    width: 70%;
  `)}

  ${mediaPhone(css`
    width: 100%;
    right: ${spaces.normal};
    text-align: right;
  `)}
`;

export const PrimaryTitle = styled.h1`
  font-size: ${fontSizes.large8};
  font-weight: ${fontWeight.bold};
  ${mediaTabPort(css`
    font-size: ${fontSizes.large4};
  `)}
  ${mediaPhone(css`
    font-size: ${fontSizes.large2};
  `)}
`;

export const SecondaryTitle = styled.h2`
  font-size: ${fontSizes.large6};
  font-weight: ${fontWeight.semiBold};
  margin-bottom: ${spaces.large4};
  ${mediaTabPort(css`
    font-size: ${fontSizes.large2};
    margin-bottom: ${spaces.large2};
  `)}
  ${mediaPhone(css`
    font-size: ${fontSizes.large2};
  `)}
`;

export const Preposition = styled.span`
  font-weight: ${fontWeight.thin};
`;

export const ProductSection = styled.div`
  width: 80%;
  margin: ${spaces.large4} auto;
  text-align: center;
`;

export const MyElement = styled(Element)`
  font-size: ${fontSizes.large6};
  ${mediaTabPort(css`
    font-size: ${fontSizes.large3};
  `)}
`;

export const ProductPreviewContainer = styled.div`
  padding: ${spaces.large4};
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  grid-gap: ${spaces.large4};
  ${mediaTabPort(css`
    grid-template-columns: auto auto;
  `)}
  ${mediaPhone(css`
    grid-template-columns: auto;
  `)}
`;

export const AdvantageSection = styled.div`
  width: 100%;
  background-color: ${otherColor.gray};
`;

export const AdvantageBanner = styled.div`
  width: 100%;
  padding: ${spaces.large3} 0;
  margin: 0 auto;
  color: ${white};
  background-color: ${otherColor.secondary};
  text-align: center;
`;

export const AdvantageTitle = styled.h2`
  font-size: ${fontSizes.large4};
  text-transform: uppercase;
  ${mediaPhone(css`
    font-size: ${fontSizes.large3};
  `)}
`;

export const SkinFeelContainer = styled.div`
  width: 80%;
  padding: ${spaces.large4};
  text-align: center;
  margin: 0 auto;
  ${mediaPhone(css`
    width: 100%;
    padding: ${spaces.large1};
  `)}
`;

export const SubTopic = styled.h3`
  font-size: ${fontSizes.large1};
  ${mediaPhone(css`
    font-size: ${fontSizes.normal};
  `)}
`;

export const SkinFeelList = styled.div`
  display: flex;
  padding: ${spaces.large4} ${spaces.large6};
  justify-content: center;
  flex-wrap: wrap;
  ${mediaTabPort(css`
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: ${spaces.large6};
    padding: ${spaces.large4} ${spaces.large8};
  `)}
  ${mediaPhone(css`
    grid-row-gap: ${spaces.large4};
    padding: ${spaces.large2};
  `)}
`;

export const IconCardWrapper = styled.div`
  margin: ${spaces.large2} ${spaces.large4};
  ${mediaPhone(css`
    margin: 0;
  `)}
`;

export const StabilityContainer = styled.div`
  position: relative;
`;

export const StabilityContent = styled.div`
  width: 50%;
  padding: ${spaces.large6};
  color: ${white};
  ${mediaTabPort(css`
    width: 80%;
  `)}
  ${mediaPhone(css`
    width: 100%;
    padding: ${spaces.large4};
  `)}
`;

export const StabilityList = styled.p`
  font-size: ${fontSizes.large1};
  font-weight: ${fontWeight.light};
  margin-top: ${spaces.large6};
  margin-left: ${(props) => props.order}px;
  border-left: 6px solid ${otherColor.primary};
  padding: 0 ${spaces.large3};
  ${mediaPhone(css`
    font-size: ${fontSizes.normal};
    margin-top: ${spaces.large4};
  `)}
`;

export const ProcessContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: ${spaces.large4} 0;
`;

export const ProcessContent = styled.div`
  width: 60%;
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: ${spaces.large4};
  padding: ${spaces.large4};
  margin: 0 auto;
  ${mediaTabLand(css`
    width: 100%;
  `)}
  ${mediaTabPort(css`
    grid-row-gap: auto;
    padding: ${spaces.large2};
  `)}
  ${mediaPhone(css`
    grid-template-columns: auto;
    padding: ${spaces.large2} 0;
  `)}
`;

export const VideoSection = styled.div`
  padding: ${spaces.large4} 0;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background-color: ${black};
`;

export const VideoContent = styled.div`
  width: 90%;
  margin: 0 auto;
`;

export const GallerySection = styled.div`
  padding: ${spaces.large4} ${spaces.large6};
  width: 80%;
  margin: 0 auto;
  ${mediaTabPort(css`
    width: 100%;
  `)}
  ${mediaPhone(css`
    text-align: center;
    padding: ${spaces.large4} 0;
  `)}
`;
