import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import {
  fontSizes,
  black,
  spaces,
  iconSizes,
  otherColor,
} from "../../../styles/mixins";
import { mediaTabPort, mediaPhone } from "../../../styles/media";

export const StyledBurger = styled.div`
  width: ${iconSizes.large1};
  height: ${iconSizes.large1};
  position: fixed;
  top: ${spaces.large4};
  right: ${spaces.large1};
  display: none;
  z-index: 888;
  cursor: pointer;

  ${mediaTabPort(css`
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  `)}

  ${mediaPhone(css`
    width: ${iconSizes.normal};
    height: ${iconSizes.normal};
  `)}

  div {
    width: ${iconSizes.large1};
    height: 0.25rem;
    background-color: ${black};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
    ${mediaPhone(css`
      width: ${iconSizes.normal};
    `)}
  }
`;

export const Options = styled.ul`
  width: 50%;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mediaTabPort(css`
    align-items: flex-start;
    flex-direction: column;
    justify-content: normal;
    background-color: ${otherColor.gray};
    padding-top: ${spaces.large4};
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    width: 40vw;
    height: 100vh;
    z-index: 777;
    transition: transform 0.3s ease-in-out;

    li {
      margin: ${spaces.large4} 0 0 ${spaces.large4};
      ${mediaPhone(css`
        margin: ${spaces.large2} 0 0 ${spaces.large2};
      `)}
    }
  `)}
`;

export const Navigator = styled(Link)`
  font-size: ${fontSizes.large1};
  color: ${black};
  ${mediaPhone(css`
    font-size: ${fontSizes.small1};
  `)}
`;

export const LanguageContainer = styled.div`
  margin: auto 0 auto auto;
`

export const Lang = styled.span`
  cursor: pointer;
  font-size: ${fontSizes.normal};
  &:not(:last-child) {
    margin-right: ${spaces.normal};
    &::after {
      content: "|";
      margin-left: ${spaces.normal};
    }
  }
  ${mediaTabPort(css`
    font-size: ${fontSizes.small1};
  `)}
`;

