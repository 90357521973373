import styled, { css } from "styled-components";
import { mediaTabLand,mediaTabPort, mediaPhone } from "../../styles/media";
import { white, spaces, fontSizes, fontWeight } from "../../styles/mixins";

export const QuoteContainer = styled.div`
  color: ${white};
  position: absolute;
  width: 60%;
  display: flex;
  flex-direction: column;
  top: 40%;
  right: ${spaces.large4};
  transform: translateY(-40%);
  align-items: flex-end;
  text-align: right;

  ${mediaTabPort(css`
    width: 70%;
  `)}
  ${mediaPhone(css`
    width: 100%;
    right: ${spaces.normal};
    text-align: right;
  `)}
`;

export const PrimaryTitle = styled.h1`
  font-size: ${fontSizes.large8};
  font-weight: ${fontWeight.bold};
  margin-bottom: ${spaces.large4};
  ${mediaTabPort(css`
    font-size: ${fontSizes.large4};
  `)}
  ${mediaPhone(css`
    font-size: ${fontSizes.large2};
  `)}
`;

export const Description = styled.p`
  margin-bottom: ${spaces.large6};
  ${mediaTabPort(css`
    font-size: ${fontSizes.normal};
    margin-bottom: ${spaces.large4};
  `)}
  ${mediaPhone(css`
    font-size: ${fontSizes.small1};
  `)}
`;

export const Explain = styled.p`
  padding: 0 ${spaces.large4};
  ${mediaTabPort(css`
    font-size: ${fontSizes.normal};
  `)}
  ${mediaPhone(css`
    font-size: ${fontSizes.small1};
  `)}
`;

export const DetailSection = styled.div`
  margin: 0 auto;
  width: 80%;
  padding: ${spaces.large4};
  ${mediaTabLand(css`
    width: 90%;
  `)}
  ${mediaPhone(css`
    padding: ${spaces.large4} ${spaces.normal};
  `)}
`;

export const Gif = styled.img`
  width: 400px;
  height: 200px;
  ${mediaPhone(css`
    width: 240px;
    height: 120px;
  `)}
`;

export const TechDetailSection = styled.div`
  width: 80%;
  padding: ${spaces.large4};
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: ${spaces.large4};
  ${mediaTabLand(css`
    width: 90%;
  `)}
  ${mediaTabPort(css`
    grid-template-columns: auto auto;
  `)}
  ${mediaPhone(css`
    width: 100%;
    grid-template-columns: auto;
  `)}
`;
