import styled, { css } from "styled-components";
import { centerCrop } from "../../styles/image";
import { spaces, fontSizes, otherColor, fontWeight } from "../../styles/mixins";
import { mediaTabPort } from "../../styles/media";
export const Container = styled.div`
  width: 280px;
  padding: ${spaces.large2} ${spaces.normal};
  text-align: center;
  border: 2px solid ${otherColor.primary};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  ${mediaTabPort(css`
    width: 240px;
  `)}
`;

export const Img = styled.img`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${spaces.normal};
  ${centerCrop}
  ${mediaTabPort(css`
    width: 40%;
  `)}
`;

export const Describe = styled.span`
  font-size: ${fontSizes.normal};
  font-weight: ${fontWeight.light};
  ${mediaTabPort(css`
    font-size: ${fontSizes.small1};
  `)}
`;
