import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { HiBadgeCheck } from "react-icons/hi";
import { fontSizes, spaces, fontWeight, otherColor,black } from "../../styles/mixins";
import {centerCrop} from "../../styles/image"
import { mediaPhone } from "../../styles/media";

export const Container = styled(Link)`
  width: 240px;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  color: ${black};
  ${mediaPhone(css`
    width: 210px;
  `)}
  &:hover{
    box-shadow: 0 0 0 1px ${otherColor.primary};
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 360px;
  overflow: hidden;
  ${mediaPhone(css`
    height: 320px;
  `)}
`;

export const Image = styled.img`
  width: 100%;
  ${centerCrop}
`;

export const Content = styled.div`
  padding: ${spaces.large2};
`

export const Title = styled.h2`
  font-size: ${fontSizes.large3};
  font-weight: ${fontWeight.medium};
  color: ${otherColor.primary};
  margin-bottom: ${spaces.large2};
`;

export const List = styled.ul`
  list-style-type: none;
`;

export const ListItem = styled.li`
  margin-bottom: ${spaces.normal};
`

export const Checked = styled(HiBadgeCheck)`
  color: ${otherColor.primary};
  margin-right: ${spaces.normal};
`
