import Feather from "../assets/feather.svg";
import Long from "../assets/24-hours.svg";
import Water from "../assets/water-drop.svg";
import Beauty from "../assets/beauty-treatment.svg";
import Uv from "../assets/uv-protection.svg";
import Formula from "../assets/formula.svg";
import Circle from '../assets/circles.svg'

export const skinAdvantages = [
  {
    src: Feather,
    describe: "home.advantages.skin.smooth",
    alt: "soft",
  },
  {
    src: Long,
    describe: "home.advantages.skin.long",
    alt: "24-hours",
  },
  {
    src: Water,
    describe: "home.advantages.skin.moisturizing",
    alt: "moisturizing",
  },
  {
    src: Beauty,
    describe: "home.advantages.skin.sebum",
    alt: "sebum control",
  },
  { src: Uv, describe: "home.advantages.skin.uv", alt: "uv filter" },
  {
    src: Formula,
    describe: "home.advantages.skin.formulate",
    alt: "easy formulate",
  },
  { src: Circle, describe: "home.advantages.skin.homogenous", alt: "homogenous" },
];
