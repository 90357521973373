import Waterproof from "../assets/waterproof.svg";
import Dropper from "../assets/dropper.svg";
import Shield from "../assets/shield.svg";
import Handle from "../assets/handle-with-care.svg";

export const processAdvantages = [
  {
    src: Waterproof,
    describe: "home.advantages.process.hydrophobic",
    alt: "Waterproof",
  },
  {
    src: Dropper,
    describe: "home.advantages.process.pigment",
    alt: "Dropper",
  },
  {
    src: Shield,
    describe:
      "home.advantages.process.size",
    alt: "Shield",
  },
  {
    src: Handle,
    describe: "home.advantages.process.handle",
    alt: "Handle",
  },
];
