import { css } from "styled-components";

export const centerCrop = css`
  vertical-align: middle;
  background-size: cover;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
`;
