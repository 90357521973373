import styled, { css } from "styled-components";
import { mediaPhone } from "../../../styles/media";
import {
  spaces,
  fontSizes,
  fontWeight,
  otherColor,
  borders,
} from "../../../styles/mixins";

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${spaces.large2};
  justify-content: center;
`;

export const Card = styled.div`
  width: 300px;
  min-height: 100px;
  border-radius: ${borders.default};
  background-color: #fff;
  padding: ${spaces.large4};
  margin: ${spaces.large2};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  &:hover {
    box-shadow: 0 0 0 1px ${otherColor.primary};
  }
`;

export const Header = styled.h2`
  font-size: ${fontSizes.large2};
  font-weight: ${fontWeight.semiBold};
  text-align: left;
  ${mediaPhone(css`
    font-size: ${fontSizes.large1};
  `)}
`;

export const ContentContainer = styled.div`
  list-style: none;
  margin-bottom: ${spaces.normal};
  text-align: left;
  align-items: center;
`;

export const AddressWrapper = styled.div`
  margin-top: ${spaces.large1};
`;

export const DetailWrapper = styled.div`
  display: flex;
  margin-bottom: ${spaces.normal};
`;

export const IconWrapper = styled.div`
  margin-right: ${spaces.normal};
`;

export const Name = styled.p`
  font-size: ${fontSizes.large1};
  font-weight: ${fontWeight.semiBold};
  opacity: 0.5;
  ${mediaPhone(css`
    font-size: ${fontSizes.normal};
  `)}
`;

export const Email = styled.a`
  font-size: ${fontSizes.small1};
  color: ${otherColor.secondary};
  cursor: pointer;
  ${mediaPhone(css`
    font-size: ${fontSizes.small1};
  `)}
`;

export const Content = styled.span`
  font-size: ${fontSizes.small1};
`;
