import Team from "../assets/team-leader.svg";
import Customer from "../assets/customer.svg";

export const missions = [
  {
    src: Team,
    alt: "team",
    describe: "about.mission.team",
  },
  {
    src: Customer,
    alt: "customer",
    describe: "about.mission.customer",
  },
];
