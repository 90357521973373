import styled, { css } from "styled-components";
import { white, borders, otherColor } from "../../styles/mixins";

export const DotContainer = styled.div`
  height: auto;
  position: relative;
  bottom: -20px;
`;

export const Dots = styled.div`
  width: 8px;
  height: 8px;
  border-radius: ${borders.circle};

  ${({ $isActive }) =>
    $isActive
      ? css`
          background-color: ${otherColor.secondary};
        `
      : css`
          background-color: ${white};
        `}
`;
