import { Title } from "./styled";

const Topic = ({ children, color,...otherProps }) => {
  return (
    <Title $color={color} {...otherProps}>
      {children}
    </Title>
  );
};
export default Topic;
