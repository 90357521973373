export const timeline = [
  {
    date: "about.history.mar",
    event: "about.history.mar.content",
  },
  {
    date: "about.history.may",
    event: "about.history.may.content",
  },
  {
    date: "about.history.july",
    event: "about.history.july.content",
  },
  {
    date: "about.history.dec",
    event: "about.history.dec.content",
  },
];