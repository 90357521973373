import styled,{css} from "styled-components";
import { Link } from "react-scroll";
import { spaces, white, otherColor, fontSizes } from "../../styles/mixins";
import { mediaPhone } from "../../styles/media";

const buttonStyled = css`
  min-width: ${spaces.large8};
  width: auto;
  color: ${white};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  background-color: ${otherColor.primary};
  padding: ${spaces.large1};
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.2s;
  ${mediaPhone(css`
    font-size: ${fontSizes.small1};
    min-width: ${spaces.large4};
    padding: ${spaces.normal};
  `)}

  &:hover {
    background-color: transparent;
    border: 2px solid ${otherColor.primary};
    color: ${otherColor.primary};
  }
`;

export const LButton = styled(Link)`
  ${buttonStyled}
`;

export const Button = styled.button`
  ${buttonStyled}
`;
