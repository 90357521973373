import styled, { css } from "styled-components";
import { spaces, otherColor, iconSizes, fontSizes } from "../../styles/mixins";
import { mediaPhone } from "../../styles/media";

export const TimelineContainer = styled.div`
  position: relative;
  margin: 0 auto;
  &::before {
    content: "";
    position: absolute;
    width: 4px;
    background-color: ${otherColor.primary};
    top: 0;
    bottom: 0;
    left: 40px;
  }
`;

export const ContentContainer = styled.div`
  padding: ${spaces.large1} ${spaces.large8};
  position: relative;
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    width: ${iconSizes.normal};
    height: ${iconSizes.normal};
    background-color: ${otherColor.primary};
    top: 15px;
    left: 34px;
    border-radius: 50%;
    z-index: 1;
  }
  ${mediaPhone(css`
    padding: ${spaces.large1} ${spaces.large6};
  `)}
`;

export const Right = styled.div`
  &::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 86px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
    ${mediaPhone(css`
      left: 54px;
    `)}
  }
`;

export const Content = styled.div`
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
`;

export const Header = styled.h2`
  ${mediaPhone(css`
    font-size: ${fontSizes.normal};
  `)}
`;

export const Event = styled.p`
  ${mediaPhone(css`
    font-size: ${fontSizes.small2};
  `)}
`;