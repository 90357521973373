import styled from "styled-components";
import { spaces, otherColor } from "../../styles/mixins";

export const Container = styled.div`
  width: 300px;
  padding: ${spaces.normal};
`;

export const Header = styled.h2`
    color: ${otherColor.secondary};
`;
