import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { FiPhoneCall, FiMail } from "react-icons/fi";
import { SiLine } from "react-icons/si";
import { MdLocationOn } from "react-icons/md";
import { renderEmail } from "react-html-email";
import { useTranslation } from "react-i18next";
import axios from "axios";

import EmailHTML from "../Email";
import LinkButton from "../LinkButton";

import Logo from "../../assets/logo-footer.svg";
import {
  Container,
  Img,
  ContentContainer,
  Topic,
  Body,
  Info,
  Divider,
  Credit,
  InputForm,
  TextArea,
  CreditWrapper,
} from "./styled";

const Footer = () => {
  const [mailMe, setMailMe] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const { t } = useTranslation();
  const isTabPort = useMediaQuery({ query: "(max-width: 900px)" });
  const isPhone = useMediaQuery({ query: "(max-width: 600px)" });
  const getWidth = () => {
    if (isPhone) {
      return "200px";
    }
    if (isTabPort) {
      return "300px";
    }
    return "400px";
  };
  const getHeight = () => {
    if (isPhone) {
      return "100px";
    }
    if (isTabPort) {
      return "150px";
    }
    return "200px";
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setMailMe({ ...mailMe, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const messageHtml = renderEmail(
      <EmailHTML name={mailMe.name} email={mailMe.email} phone={mailMe.phone}>
        {" "}
        {mailMe.message}
      </EmailHTML>
    );
    await axios({
      method: "POST",
      url: "https://alphatech-api.herokuapp.com/api/sendemail",
      data: {
        name: mailMe.name,
        messageHtml: messageHtml,
      },
    }).then((response) => {
      if (response.data.msg === "success") {
        alert(t("footer.alert.success"));
        document.location.reload(true);
      } else if (response.data.msg === "fail") {
        alert(t("footer.alert.fail"));
      }
    });
  };

  return (
    <Container>
      <Img src={Logo} alt='logo' />
      <ContentContainer>
        <Body>
          <Topic name='contact'>{t("footer.contact")}</Topic>
          <Info>
            <div>
              <FiPhoneCall />
            </div>
            &nbsp; +66 2313 3756-57
          </Info>
          <Info>
            <div>
              <SiLine />
            </div>
            &nbsp; @alphatechscience
          </Info>
          <Info>
            <div>
              <FiMail />
            </div>
            <span>
              <p>&nbsp; info@athena.co.th,</p>
              <p>&nbsp; info@alphatechscience.com</p>
            </span>
          </Info>
        </Body>
        <Body>
          <Topic name='address'>{t("footer.address")}</Topic>
          <Info>
            <div>
              <MdLocationOn />
            </div>
            &nbsp;899/63 Moo. 21 Soi Jongsiriparkland Klongarsia Road, T.
            Blangplee Yai, A. Bangplee Samutprakarn 10540, THAILAND
          </Info>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3878.2049019461056!2d100.67674181482856!3d13.584289890455974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d595a4ea7cf81%3A0xbd644d7aea120895!2sAthena%20Corp%20Co.%2C%20LTD.!5e0!3m2!1sth!2sth!4v1604830138783!5m2!1sth!2sth'
            style={{ width: getWidth(), height: getHeight(), border: "none" }}
            title="map"
          ></iframe>
        </Body>
        <Body>
          <Topic name='contactUs'>{t("footer.contactus")}</Topic>
          <form onSubmit={handleSubmit}>
            <Body>
              <InputForm
                type='text'
                name='name'
                placeholder={t(`footer.form.name`)}
                onChange={handleChange}
              />
              <InputForm
                type='email'
                name='email'
                placeholder={t(`footer.form.email`)}
                onChange={handleChange}
              />
              <InputForm
                type='tel'
                name='phone'
                placeholder={t(`footer.form.phone`)}
                onChange={handleChange}
              />
              <TextArea
                placeholder={t(`footer.form.msg`)}
                onChange={handleChange}
                name='message'
              ></TextArea>
              <LinkButton type='submit' isLink={false}>
                {t("footer.form.submit")}
              </LinkButton>
            </Body>
          </form>
        </Body>
      </ContentContainer>
      <Divider />
      <CreditWrapper>
        <Credit>
          Athena Corp &copy; 2020,{" "}
          <a href='https://www.athena.co.th/'>www.athena.co.th</a>
        </Credit>
      </CreditWrapper>
    </Container>
  );
};

export default Footer;
