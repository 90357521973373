export const distributorList = [
  {
    region: "about.distributor.asia",
    information: [
      {
        country: ["about.distributor.china"],
        contact: [
          {
            company: "Guangzhou JinHang Biotechnology Co.,Ltd.",
            teams: [
              {
                name: "Ms. Willow Wang",
                email: "willowwang128@163.com",
                address:
                  "Room 818/820,Building C4,Jiahua Square,No.185 Yingbin Avenue,Xinya Street,Huadu District, Guangzhou,GuangDong 510800",
                tel: "(+86)020-8601 1186, 136 0227 0644",
              },
            ],
          },
        ],
      },
      {
        country: ["about.distributor.taiwan"],
        contact: [
          {
            company: "Pu Feng Biotech Co.,Ltd.",
            teams: [
              {
                name: "Mr. David Tseng",
                email: "david.tseng@pf-bio.com",
                address:
                  "11F., No. 101, Sec. 2, Nanjing E. Rd, Zhongshan Dist., Taipei City 104",
                tel: "+886-2-55686040",
                fax: "+886-2-25630388",
              },
            ],
          },
        ],
      },
      {
        country: ["about.distributor.malaysia"],
        contact: [
          {
            company: "CTC Bioscience Sdn Bhd",
            teams: [
              {
                name: "S.Y. Chin",
                email: "sychin@ctc-bio.com",
                address:
                  "17, Jalan Industri Mas 6, Taman Mas Puchong,47100 Puchong, Selangor",
                tel: "+603 80606100",
                mobile: "+6012 2970528",
                fax: "+603 80606200",
              },
            ],
          },
        ],
      },
      {
        country: ["about.distributor.philippines"],
        contact: [
          {
            company: "Euro Chemicals, Inc.",
            teams: [
              {
                name: "Miguel Plana",
                email: "miguel.plana@eurocheminc.com",
                address:
                  "Lot 2 Arty II Subd., Corner Road I, Mindanao Ave., Quezon City",
                tel: "(632) 930-4715 / 930-0982",
                fax: "(632) 930-1153",
              },
            ],
          },
        ],
      },
      {
        country: ["about.distributor.indonesia"],
        contact: [
          {
            company: "PT. MARGA DWI KENCANA",
            teams: [
              {
                name: "Ms Elisabeth Indryati W",
                email: "indryati@mdkencana.co.id",
                address:
                  "RUKO DAAN MOGOT ARCADIA BLOK. E1 NO. 12 JL. DAAN MOGOT RAYA KM.22 KEL. BATUCEPER KEC. BATUCEPER KOTA TANGERANG 15122",
                tel: "62-21 5517456",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    region: "about.distributor.europe",
    information: [
      {
        country: [
          "about.distributor.italy",
          "about.distributor.poland",
          "about.distributor.russia",
        ],
        contact: [
          {
            company: "B & C SPA",
            teams: [
              {
                name: "Roberta Donadelli",
                email: "info@beautyandcosmetic.eu",
                address: "Via F.lli d’Italia, 5 21052 BUSTO ARSIZIO VA",
                tel: "+39 0331 606842",
                fax: "+39 0331 606173",
              },
            ],
          },
        ],
      },
      {
        country: [
          "about.distributor.switzerland",
          "about.distributor.france",
          "about.distributor.germany",
        ],
        contact: [
          {
            company: "NAGASE (EUROPA) GmbH ",
            teams: [
              {
                name: ["Mr. Mahito SAITO", "Ms. Harmonie MAGNIEZ"],
                email: ["saito@nagase.eu", "magniez@nagase.eu"],
                address: "60 Avenue Rockefeller 69008 Lyon",
                tel: "+33-(0)4 26 49 40 04",
              },
            ],
          },
        ],
      },
    ],
  },
];
