import {Fragment} from "react";
import { LButton,Button } from "./styled";

const LinkButton = ({ children, toElement, isLink=true, ...otherProps }) => {
  return (
    <Fragment>
      {isLink ? (
        <LButton to={toElement} smooth duration={1000}>
          {children}
        </LButton>
      ) : (
        <Button {...otherProps}>
          {children}
        </Button>
      )}
    </Fragment>
  );
};
export default LinkButton;
