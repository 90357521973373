import styled, { css } from "styled-components";
import { Element } from "react-scroll";
import {
  spaces,
  fontSizes,
  otherColor,
  fontWeight,
  borders,
  black,
} from "../../styles/mixins";
import { centerCrop } from "../../styles/image";
import { mediaTabLand, mediaTabPort, mediaPhone } from "../../styles/media";

export const Container = styled.div`
  padding: ${spaces.large4} ${spaces.large8};
  background-color: #fff;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
  ${mediaTabLand(css`
    padding: ${spaces.large4} ${spaces.large2};
  `)}
  ${mediaTabPort(css`
    padding: ${spaces.large2} ${spaces.large4};
  `)}
  ${mediaPhone(css`
    padding: ${spaces.large2} ${spaces.normal};
  `)}
`;

export const Img = styled.img`
  width: 300px;
  ${centerCrop}
  ${mediaPhone(css`
    width: 240px;
  `)}
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  ${mediaTabPort(css`
    grid-template-columns: auto auto;
  `)}
  ${mediaPhone(css`
    grid-template-columns: auto;
  `)}
`;

export const Body = styled.div`
  padding: ${spaces.normal};
  display: flex;
  flex-direction: column;
`;

export const Topic = styled(Element)`
  font-size: ${fontSizes.large1};
  margin-bottom: ${spaces.normal};
  color: ${otherColor.secondary};
  font-weight: ${fontWeight.medium};
`;

export const Info = styled.div`
  padding: ${spaces.normal};
  font-size: ${fontSizes.small1};
  display: flex;
`;

export const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${otherColor.primary};
  margin: ${spaces.normal};
`;

export const Credit = styled.div`
  font-size: ${fontSizes.small2};
  text-align: center;
  padding: 0 ${spaces.normal};
  margin-right: auto;
  ${mediaPhone(css`
    font-size: ${fontSizes.small3};
  `)}
`;

const formStyled = css`
  background-color: ${otherColor.gray};
  padding: ${spaces.large1};
  margin-bottom: ${spaces.normal};
  border-radius: ${borders.default};
  border: 1px solid #fff;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${otherColor.primary};
  }
  &::placeholder {
    color: ${black};
    opacity: 0.5;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${black};
    opacity: 0.5;
  }
`;

export const InputForm = styled.input`
  ${formStyled}
`;

export const TextArea = styled.textarea`
  height: 80px;
  ${formStyled}
`;

export const CreditWrapper = styled.div`
  margin: 0 auto;
`;
