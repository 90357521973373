import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import Divider from "../Divider";
import VideoCard from "../VideoCard";

import {
  Header,
  SubContent,
  DetailContainer,
  Detail,
  EachTopicContainer,
  Topic,
  Content,
  RecommedListItem,
  ProductListContainer,
  ProductItem,
  ListContainer,
  CircleList,
  CheckList,
} from "./styled";
import { data } from "../../data/products";

const recommendFor = {
  EM: "product.recommend.em",
  LQ: "product.recommend.lq",
  P: "product.recommend.p",
  PM: "product.recommend.pm",
  LS: "product.recommend.ls",
};

const ProductDetail = () => {
  const { productId } = useParams();
  const { t } = useTranslation();
  const [autoPlay, setAutoPlay] = useState(false);
  const isPhone = useMediaQuery({ query: "(max-width: 600px)" });

  const handleReady = () => {
    setAutoPlay(true);
  };
 
  let info = data.find((obj) => {
    if (obj.subSeries) {
      return obj.subSeries.find((subObj) => subObj.id === productId);
    } else {
      return obj.id === productId;
    }
  });
  let products = undefined;
  if (info.subSeries) {
    info = info.subSeries.find((obj) => obj.id === productId);
  } else {
    products = info.product;
  }

  return (
    <Fragment>
      <Header>
        <h1>{info.name}</h1>
        <SubContent>{info.inci}</SubContent>
        <Divider width='100%' />
      </Header>
      {info.video && (
        <VideoCard
          onReady={handleReady}
          playing={autoPlay}
          url={info.video}
          width={isPhone ? "280px" : "640px"}
          height={isPhone ? "210px" : "360px"}
        />
      )}

      <DetailContainer>
        <Detail>
          {info.performance && (
            <EachTopicContainer>
              <Topic>{t("product.performance.title")}</Topic>
              <Content>{t(info.performance)}</Content>
            </EachTopicContainer>
          )}

          {info.special && (
            <EachTopicContainer>
              <Topic>{t("product.speciality.title")}</Topic>
              <Content>{t(info.special)}</Content>
            </EachTopicContainer>
          )}

          {info.skinFeel && (
            <EachTopicContainer>
              <Topic>{t("product.skin.title")}</Topic>
              <Content>{t(info.skinFeel)}</Content>
            </EachTopicContainer>
          )}

          {info.waterRepel && (
            <EachTopicContainer>
              <Topic>{t("product.water.title")}</Topic>
              <Content>{t(info.waterRepel)}</Content>
            </EachTopicContainer>
          )}

          {info.oilRepel && (
            <EachTopicContainer>
              <Topic>{t("product.oil.title")}</Topic>
              <Content>{t(info.oilRepel)}</Content>
            </EachTopicContainer>
          )}

          {info.color && (
            <EachTopicContainer>
              <Topic>{t("product.color.title")}</Topic>
              <Content>{t(info.color)}</Content>
            </EachTopicContainer>
          )}
        </Detail>

        <Detail>
          {info.recommend && (
            <EachTopicContainer>
              <Topic>{t("product.for.title")}</Topic>
              <ListContainer>
                {info.recommend.map((item, id) => (
                  <RecommedListItem key={`${item}_${id}`}>
                    <div>
                      <CheckList />
                    </div>
                    <div>{t(recommendFor[item])}</div>
                  </RecommedListItem>
                ))}
              </ListContainer>
            </EachTopicContainer>
          )}

          {info.dose && (
            <EachTopicContainer>
              <Topic>{t("product.dose.title")}</Topic>
              <Content>{info.dose}</Content>
            </EachTopicContainer>
          )}
        </Detail>
      </DetailContainer>
      {products && (
        <ProductListContainer>
          <Topic>{t("product.products.title")}</Topic>
          <ListContainer>
            {products.map((item, index) => (
              <ProductItem key={index}>
                <CircleList />
                {item}
              </ProductItem>
            ))}
          </ListContainer>
        </ProductListContainer>
      )}
    </Fragment>
  );
};

export default ProductDetail;
