import styled,{css} from "styled-components";
import { centerCrop } from "../../styles/image";
import {mediaPhone} from "../../styles/media"
export const Img = styled.img`
  width: 100%;
  height: 88vh;
  object-fit: cover;
  -webkit-filter: grayscale(70%) brightness(40%); /* Safari 6.0 - 9.0 */
  -moz-filter: grayscale(70%) brightness(40%);
  -o-filter: grayscale(70%) brightness(40%);
  -ms-filter: grayscale(70%) brightness(40%);
  filter: grayscale(70%) brightness(40%);
  ${centerCrop}
  ${mediaPhone(css`
    height: 44vh;
  `)}
`;

export const Container = styled.div`
  position: relative;
`;
