import { useMediaQuery } from "react-responsive";
import CommonMenu from "../../components/CommonMenu";
import ProductDetail from "../../components/ProductDetail";
import { Container, SideMenu, DetailContainer } from "./styled";
import "rc-menu/assets/index.css";

const ProductPage = () => {
  const isTabPort = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <Container>
      {isTabPort ? (
        <SideMenu>
          <CommonMenu menuMode='horizontal' />
        </SideMenu>
      ) : (
        <SideMenu>
          <CommonMenu menuMode='inline'/>
        </SideMenu>
      )}
      <DetailContainer>
        <ProductDetail />
      </DetailContainer>
    </Container>
  );
};
export default ProductPage;
