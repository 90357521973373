import { css } from "styled-components";

//600px
export const mediaPhone = (content) => css`
  @media (max-width: 37.5em) {
    ${content}
  }
`;

//900px
export const mediaTabPort = (content) => css`
  @media (max-width: 56.25em) {
    ${content}
  }
`;

//1200px
export const mediaTabLand = (content) => css`
  @media (max-width: 75em) {
    ${content}
  }
`;

//1800px
export const mediaBigDesktop = (content) => css`
  @media (max-width: 112.5em) {
    ${content}
  }
`;
