import { useTranslation } from "react-i18next";

import {
  Container,
  Title,
  List,
  ListItem,
  Checked,
  Content,
  Image,
  ImageWrapper,
} from "./styled";

const ProductPreview = ({ title, properties,id,src }) => {
  const { t } = useTranslation();
  
  return (
    <Container to={`/products/${id}`}>
      <ImageWrapper><Image src={src} alt={title}/></ImageWrapper>
      <Content>
        <Title>{title}</Title>
        <List>
          {properties.map((item,index) => (
            <ListItem key={`${index}`}><Checked/>{t(`${item}`)}</ListItem>
          ))}
        </List>
      </Content>
    </Container>
  );
};
export default ProductPreview;
