import { HiOutlineMail } from "react-icons/hi";
import { GoLocation } from "react-icons/go";
import { FiPhone } from "react-icons/fi";
import { BsPhone } from "react-icons/bs";
import { FaFax } from "react-icons/fa";
import {
  CardContainer,
  Card,
  Header,
  DetailWrapper,
  Name,
  Email,
  ContentContainer,
  IconWrapper,
  Content,
  AddressWrapper,
} from "./styled";
import { Fragment } from "react";

const DistributorCard = ({ companies }) => {
  return (
    <CardContainer>
      {companies.map((item, index) => (
        <Card key={index}>
          {item.company && <Header>{item.company}</Header>}
          {item.teams.map((person, i) => (
            <ContentContainer key={`${person.name}_${i}`}>
              {typeof person.name === "object" ? (
                person.name.map((n, i) => <Name key={n}>{n}</Name>)
              ) : (
                <Name>{person.name}</Name>
              )}
              <AddressWrapper>
                {person.address && (
                  <DetailWrapper>
                    <IconWrapper>
                      <GoLocation />
                    </IconWrapper>
                    <Content>{person.address}</Content>
                  </DetailWrapper>
                )}
              </AddressWrapper>
              {person.email && (
                <Fragment>
                    {typeof person.email === "object" ? 
                      (person.email.map((e, i) => 
                        <DetailWrapper key={`${e}_${i}`}>
                          <IconWrapper>
                            <HiOutlineMail />
                          </IconWrapper>
                          <Email href={`mailto:${e}`}>{e}</Email>
                        </DetailWrapper>
                      )
                    ) : (
                        <DetailWrapper>
                          <IconWrapper>
                            <HiOutlineMail />
                          </IconWrapper>
                        <Email href={`mailto:${person.email}`}>
                          {person.email}
                        </Email>
                        </DetailWrapper>
                    )}
                </Fragment>
              )}
              {person.tel && (
                <DetailWrapper>
                  <IconWrapper>
                    <FiPhone />
                  </IconWrapper>
                  <Content>Tel: {person.tel}</Content>
                </DetailWrapper>
              )}
              {person.fax && (
                <DetailWrapper>
                  <IconWrapper>
                    <FaFax />
                  </IconWrapper>
                  <Content>Fax: {person.fax}</Content>
                </DetailWrapper>
              )}
              {person.mobile && (
                <DetailWrapper>
                  <IconWrapper>
                    <BsPhone />
                  </IconWrapper>
                  <Content>Mobile: {person.mobile}</Content>
                </DetailWrapper>
              )}
            </ContentContainer>
          ))}
        </Card>
      ))}
    </CardContainer>
  );
};

export default DistributorCard;
