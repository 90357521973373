import styled from "styled-components";

import { spaces } from "../../styles/mixins";

export const Container = styled.div`
  width: 100%;
  height: 12vh;
  display: flex;
  padding: ${spaces.large1};
  z-index: 999;
`;
export const Logo = styled.img`
  height: ${spaces.large6};
`;


