import styled from "styled-components";
import { spaces } from "../../styles/mixins";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const VideoContainer = styled.div`
  padding: ${spaces.large2};
  margin: 0 auto;
`;

