import { Fragment } from "react";
import { Element } from "react-scroll";
import { useTranslation } from "react-i18next";

import Topic from "../../components/Topic";
import CoverImg from "../../components/CoverImg";
import LinkButton from "../../components/LinkButton";
import TechCard from "../../components/TechCard";
import TechnologyGif from "../../assets/technology.gif";

import { data } from "../../data/techInfo";

import {
  QuoteContainer,
  PrimaryTitle,
  Description,
  Explain,
  DetailSection,
  Gif,
  TechDetailSection,
} from "./styled";

const TechnoImage = {
  medium: "https://i.ibb.co/Fn3TxZD/Tech.jpg",
  small: "https://i.ibb.co/nRndtNX/Tech-small.jpg",
};

const TechnoPage = () => {
  const { t } = useTranslation();

  return (
    <Fragment key='technology'>
      <CoverImg
        medium={TechnoImage.medium}
        small={TechnoImage.small}
        alt='cover'
      >
        <QuoteContainer>
          <PrimaryTitle>{t("technology.cover.quote.title")}</PrimaryTitle>
          <Description>{t("technology.cover.quote.content")}</Description>
          <LinkButton toElement='technology'>
            {t("technology.cover.button")}
          </LinkButton>
        </QuoteContainer>
      </CoverImg>
      <DetailSection>
        <Element name='technology'>
          <Topic>{t("techonology.detail.title")}</Topic>
          <br />
          <Explain>{t("techonology.detail.content")}</Explain>
          <br />
          <div style={{ textAlign: "center" }}>
            <Gif src={TechnologyGif} alt='surface modification' />
          </div>
        </Element>
      </DetailSection>
      <TechDetailSection>
        {data.map((item, index) => (
          <TechCard
            title={item.title}
            info={item.info}
            key={`${item.title}_${index}`}
          />
        ))}
      </TechDetailSection>
    </Fragment>
  );
};
export default TechnoPage;
