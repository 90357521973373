import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import DistributorCard from "./DistributorCard";
import {
  Container,
  Region,
  CountriesContainer,
  CountryWrapper,
  CountryTitle,
} from "./styled";

const Distributor = ({ info }) => {
  const { t } = useTranslation();
  const getCountryName = (countryArr) => {
    const len = countryArr.length;
    if (len > 1) {
      return countryArr.map((item, index) => {
        return (
          <span>
            {t(item)},<br />
          </span>
        );
      });
    } else {
      return <span>{t(countryArr)}</span>;
    }
  };
  return (
    <Container>
      {info.map((region, i) => (
        <Fragment key={`${region.region}_${i}`}>
          <Region>{t(region.region)}</Region>
          <CountriesContainer>
            {region.information.map((country, index) => (
              <CountryWrapper key={`${country.country}_${index}`}>
                <CountryTitle>{getCountryName(country.country)}</CountryTitle>
                <DistributorCard companies={country.contact} />
              </CountryWrapper>
            ))}
          </CountriesContainer>
        </Fragment>
      ))}
    </Container>
  );
};

export default Distributor;
