import { Fragment } from "react";
import { Parallax } from "react-parallax";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import Zoom from "react-medium-image-zoom";

import Topic from "../../components/Topic";
import Divider from "../../components/Divider";
import IconCard from "../../components/IconCard";
import Timeline from "../../components/Timeline";
import Distributor from "../../components/Distributor";

import { missions } from "../../data/about";
import { awards } from "../../data/certificates";
import { distributorList } from "../../data/distributors";
import {
  QuoteContainer,
  Word,
  MissionSection,
  MissionContainer,
  HistorySection,
  HistoryContent,
  CoreValueSection,
  CoreValueWrapper,
  CoreValueContent,
  DistributorTitle,
  DistributorSection,
  RegionContainer,
  CertificateSection,
  ImageContainer,
  Img,
} from "./styled";

const aboutImage = {
  medium: "https://i.ibb.co/0B5xJtb/About.jpg",
  small: "https://i.ibb.co/JxgDGW3/About-small.jpg",
};

const AboutPage = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 600px)" });
  const { t } = useTranslation();

  return (
    <Fragment key='company'>
      <Parallax
        bgImageSrcSet={`${aboutImage.medium} 1800w, ${aboutImage.small} 600w`}
        strength={500}
        bgImage={aboutImage.medium}
        bgImageAlt='company background'
        renderLayer={(percentage) => (
          <div
            style={{
              position: "absolute",
              background: `rgba(0, 0, 0, 0.7)`,
              width: "100%",
              height: "100%",
            }}
          />
        )}
      >
        <div style={{ height: "50vh", width: "100%", position: "relative" }}>
          <QuoteContainer>
            <Word>{t("about.cover.title")}</Word>
          </QuoteContainer>
        </div>
      </Parallax>
      <MissionSection>
        <Topic>{t("about.mission.title")}</Topic>
        <Divider />
        <MissionContainer>
          {missions.map((item, index) => (
            <IconCard
              key={`${item.alt}_${index}`}
              src={item.src}
              alt={item.alt}
              describe={item.describe}
              width={isPhone ? "230px" : "350px"}
              height='auto'
              imgSize={isPhone ? "30%" : "50%"}
            />
          ))}
        </MissionContainer>
      </MissionSection>
      <HistorySection>
        <HistoryContent>
          <Topic>{t("about.history.title")}</Topic>
          <br></br>
          <Timeline />
        </HistoryContent>
      </HistorySection>
      <CoreValueSection>
        <Topic style={{ alignSelf: "center" }}>{t("about.core.title")}</Topic>
        <CoreValueWrapper>
          <CoreValueContent>{t("about.core.customer")}</CoreValueContent>
          <CoreValueContent>{t("about.core.integrity")}</CoreValueContent>
          <CoreValueContent>{t("about.core.team")}</CoreValueContent>
          <CoreValueContent>{t("about.core.global")}</CoreValueContent>
          <CoreValueContent>{t("about.core.passion")}</CoreValueContent>
        </CoreValueWrapper>
      </CoreValueSection>
      <DistributorSection>
        <DistributorTitle>{t("about.distributor.title")}</DistributorTitle>
        <RegionContainer>
          <Distributor info={distributorList} />
        </RegionContainer>
      </DistributorSection>
      <CertificateSection>
        <Topic>{t("about.certificate")}</Topic>
        <ImageContainer>
          {awards.map((item, index) => (
            <Zoom>
              <Img
                key={`${item.alt}_${index}`}
                srcSet={`${item.medium} 1800w, ${item.small} 600w`}
                src={item.medium}
                alt={item.alt}
              />
            </Zoom>
          ))}
        </ImageContainer>
      </CertificateSection>
    </Fragment>
  );
};
export default AboutPage;

