import { Img, Container } from "./styled";

const CoverImg = ({ medium, small, alt,children }) => {
  return (
    <Container>
      <Img srcSet={`${medium} 1800w, ${small} 600w`} src={medium} alt={alt} />
      {children}
    </Container>
  );
};
export default CoverImg;
