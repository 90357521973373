export const previews = [
  {
    title: "AS Series",
    id: "as",
    list: [
      "home.product.preview.skin.as",
      "home.product.preview.performance.as",
    ],
    src: "https://i.ibb.co/t39gk4f/as.png",
  },
  {
    title: "ES Series",
    id: "es",
    list: [
      "home.product.preview.skin.es",
      "home.product.preview.performance.es",
    ],
    src: "https://i.ibb.co/VJJY5p9/es.png",
  },
  {
    title: "DS Series",
    id: "ds",
    list: [
      "home.product.preview.skin.ds",
      "home.product.preview.performance.ds",
    ],
    src: "https://i.ibb.co/ygVW0QM/ms.png",
  },
];
