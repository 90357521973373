import Athena from "../../assets/athena-logo.svg";
import Burger from "./Burger"
import { Container, Logo } from "./styled";

const Nav = () => {

  return (
    <Container>
      <Logo src={`${Athena}`} alt='logo' />
      <Burger/>
    </Container>
  );
};
export default Nav;
