import styled, { css } from "styled-components";
import {
  white,
  fontSizes,
  fontWeight,
  otherColor,
  spaces,
} from "../../styles/mixins";
import { mediaTabLand, mediaTabPort, mediaPhone } from "../../styles/media";
import { centerCrop } from "../../styles/image";

export const QuoteContainer = styled.div`
  color: ${white};
  position: absolute;
  width: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  ${mediaTabPort(css`
    width: 70%;
  `)}

  ${mediaPhone(css`
    width: 100%;
  `)}
`;

export const Word = styled.h1`
  border-bottom: 2px solid ${otherColor.primary};
  font-size: ${fontSizes.large8};
  font-weight: ${fontWeight.bold};
  transition: all 0.2s;
  &:hover {
    font-size: ${fontSizes.large10};
    ${mediaTabPort(css`
      font-size: ${fontSizes.large6};
    `)}
  }
  ${mediaTabPort(css`
    font-size: ${fontSizes.large4};
    border: none;
  `)}
`;

export const MissionSection = styled.div`
  width: 80%;
  height: auto;
  padding: ${spaces.large4} 0;
  margin: 0 auto;
  text-align: center;
`;

export const MissionContainer = styled.div`
  padding: ${spaces.large6};
  display: flex;
  justify-content: space-evenly;
  ${mediaPhone(css`
    flex-direction: column;
    padding: ${spaces.large4} 0;
  `)}
`;

export const HistorySection = styled.div`
  width: 100%;
  background-color: ${otherColor.gray};
  padding: ${spaces.large4} 0;
`;

export const HistoryContent = styled.div`
  width: 60%;
  margin: 0 auto;
  ${mediaPhone(css`
    width: 90%;
  `)}
`;

export const CoreValueSection = styled.div`
  width: 50%;
  margin: 0 auto;
  padding: ${spaces.large4};
  display: flex;
  justify-content: space-evenly;
  ${mediaTabPort(css`
    width: 80%;
  `)}
  ${mediaPhone(css`
    width: 100%;
    padding: ${spaces.large2};
    justify-content: space-between;
    font-size: ${fontSizes.small1};
  `)}
`;

export const CoreValueWrapper = styled.div`
  &::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 136px;
    background-color: ${otherColor.primary};
    left: 51%;
    ${mediaPhone(css`
      left: 45%;
    `)}
  }
`;

export const CoreValueContent = styled.p`
  margin-top: ${spaces.normal};
`;

export const DistributorTitle = styled.h1`
  font-size: ${fontSizes.large6};
  font-weight: ${fontWeight.bold};
  text-align: center;
  ${mediaPhone(css`
    font-size: ${fontSizes.large5};
  `)}
`;

export const DistributorSection = styled.div`
  background-color: ${otherColor.gray};
  width: 100%;
  padding: ${spaces.large6};
  ${mediaPhone(css`
    padding: ${spaces.large6} ${spaces.large2};
  `)}
`;

export const RegionContainer = styled.div`
  width: 80%;
  padding: ${spaces.large6} ${spaces.large2};
  margin: 0 auto;
  ${mediaTabLand(css`
    width: 100%;
  `)}
  ${mediaPhone(css`
    padding: ${spaces.large6} 0;
  `)}
`;

export const CertificateSection = styled.div`
  width: 100%;
  padding: ${spaces.large4};
  text-align: center;
`;

export const ImageContainer = styled.div`
  display: grid;
  padding: ${spaces.large4};
  grid-gap: ${spaces.large4};
  grid-template-columns: auto auto;
  justify-content: center;
  ${mediaPhone(css`
    grid-template-columns: auto;
  `)}
`;

export const Img = styled.img`
  width: 400px;
  transition: all 0.2s;
  ${centerCrop}
  ${mediaTabPort(css`
    width: 300px;
  `)}
`;
