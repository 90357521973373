import { useTranslation } from "react-i18next";

import {Container,Img,Describe} from "./styled"

const ProcessCard = ({ src, alt, describe }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Img src={src} alt={alt} />
      <Describe>{t(describe)}</Describe>
    </Container>
  );
};
export default ProcessCard