import { Email, Item } from "react-html-email";

const EmailHTML = ({ name, email, phone, children }) => {
  return (
    <Email title='Customer Contact'>
      <Item>
        <h2>Email from customer 📧</h2>
        <hr />
        <p>
          <span style={{ fontWeight: "bold" }}>Name :</span> {name}
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Email address :</span> {email}
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Phone no :</span> {phone}
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Message:</span> {children}
        </p>
      </Item>
    </Email>
  );
};

export default EmailHTML;
