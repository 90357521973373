import { Fragment } from "react";
import { Parallax } from "react-parallax";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";

import CoverImg from "../../components/CoverImg";
import LinkButton from "../../components/LinkButton";
import Divider from "../../components/Divider";
import ProductPreview from "../../components/ProductPreview";
import IconCard from "../../components/IconCard";
import ProcessCard from "../../components/ProcessCard";
import Topic from "../../components/Topic";
import VideoCard from "../../components/VideoCard";
import Swipe from "../../components/Swipe";

import { previews } from "../../data/productPreview";
import { skinAdvantages } from "../../data/skinFeel";
import { stabilities } from "../../data/stability";
import { processAdvantages } from "../../data/process";
import { collections } from "../../data/videos";
import { officeImages } from "../../data/office";
import {
  QuoteContainer,
  PrimaryTitle,
  Preposition,
  SecondaryTitle,
  ProductSection,
  MyElement,
  ProductPreviewContainer,
  AdvantageSection,
  AdvantageTitle,
  AdvantageBanner,
  SkinFeelContainer,
  SubTopic,
  SkinFeelList,
  IconCardWrapper,
  StabilityContainer,
  StabilityContent,
  StabilityList,
  ProcessContainer,
  ProcessContent,
  VideoSection,
  VideoContent,
  GallerySection,
} from "./styled";

const homeImage = {
  medium: "https://i.ibb.co/hHg26hQ/Home.jpg",
  small: "https://i.ibb.co/729SW18/Home-small.jpg",
};

const HomePage = () => {
  const isBigDesktop = useMediaQuery({ query: "(max-width: 1800px)" });
  const isTabLand = useMediaQuery({ query: "(max-width: 1200px)" });
  const isTabPort = useMediaQuery({ query: "(max-width: 900px)" });
  const isPhone = useMediaQuery({ query: "(max-width: 600px)" });
  const { t, i18n } = useTranslation();

  const getLanguage = () => {
    return (
      i18n.language ||
      (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
      "en"
    );
  };

  const getImages = () => {
    const arr = [...officeImages];
    if (isPhone) {
      arr.forEach((item) => (item.originalHeight = "270px"));
    } else if (isTabPort) {
      arr.forEach((item) => (item.originalHeight = "450px"));
    } else if (isTabLand) {
      arr.forEach((item) => (item.originalHeight = "630px"));
    } else if (isBigDesktop) {
      arr.forEach((item) => (item.originalHeight = "720px"));
    }
    return arr;
  };

  return (
    <Fragment key="home">
      <CoverImg medium={homeImage.medium} small={homeImage.small} alt="cover">
        <QuoteContainer>
          {getLanguage() === "zh-hk" ? (
            <Fragment>
              <PrimaryTitle>{t("home.cover.quote.primary")}</PrimaryTitle>
              <SecondaryTitle>{t("home.cover.quote.secondary")}</SecondaryTitle>
            </Fragment>
          ) : (
            <Fragment>
              <PrimaryTitle>
                {t("home.cover.quote_1")}{" "}
                <Preposition>{t("home.cover.quote_2")}</Preposition>{" "}
                {t("home.cover.quote_3")}
              </PrimaryTitle>
              <SecondaryTitle>
                {t("home.cover.quote_4")} <Preposition>&</Preposition>
                {t("home.cover.quote_5")}
              </SecondaryTitle>
            </Fragment>
          )}

          <LinkButton toElement="product">{t("home.cover.button")}</LinkButton>
        </QuoteContainer>
      </CoverImg>

      <VideoSection
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50vh",
          overflow: "hidden",
        }}
      >
        <iframe
          title="myFrame"
          src="https://drive.google.com/file/d/1ua99lfEWihS11VnKDSwHvfbE3VaG0q0g/preview"
          width="50%"
          height="480"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          style={{
            borderRadius: "20px",
            boxShadow: "0 0 20px rgba(0, 0, 0, 0.7)",
          }}
        ></iframe>
      </VideoSection>

      <ProductSection>
        <MyElement name="product">{t("home.product.preview.title")}</MyElement>
        <Divider />
        <ProductPreviewContainer>
          {previews.map((item, index) => (
            <ProductPreview
              key={`${item.title}_${index}`}
              title={item.title}
              properties={item.list}
              id={item.id}
              src={item.src}
            />
          ))}
        </ProductPreviewContainer>
      </ProductSection>
      <AdvantageSection>
        <AdvantageBanner>
          <AdvantageTitle>{t("home.advantages.title")}</AdvantageTitle>
        </AdvantageBanner>
        <SkinFeelContainer>
          <Topic>{t("home.advantages.title.secondary")}</Topic>
          <Divider />
          <SubTopic>{t("home.advantages.skin")}</SubTopic>
          <SkinFeelList>
            {skinAdvantages.map((item, index) => (
              <IconCardWrapper key={`${item.alt}_${index}`}>
                <IconCard
                  src={item.src}
                  alt={item.alt}
                  describe={item.describe}
                  imgSize={isPhone && "50%"}
                />
              </IconCardWrapper>
            ))}
          </SkinFeelList>
        </SkinFeelContainer>
        <StabilityContainer>
          <Parallax
            bgImageSrcSet={`${stabilities.background} 1800w, ${stabilities.backgroundSmall} 600w`}
            strength={500}
            bgImage={stabilities.background}
            bgImageAlt="stability background"
            renderLayer={(percentage) => (
              <div
                style={{
                  position: "absolute",
                  background: `rgba(0, 0, 0, 0.7)`,
                  width: "100%",
                  height: "100%",
                }}
              />
            )}
          >
            <StabilityContent>
              <Topic color="#fafafa">
                {t("home.advantages.stability.title")}
              </Topic>
              {stabilities.content.map((item, index) => (
                <StabilityList
                  order={(index + 1) * 24}
                  key={`${item}_${index}`}
                >
                  {t(`${item}`)}
                </StabilityList>
              ))}
            </StabilityContent>
          </Parallax>
        </StabilityContainer>
        <ProcessContainer>
          <Topic>{t("home.advantages.process.title")}</Topic>
          <ProcessContent>
            {processAdvantages.map((item, index) => (
              <ProcessCard
                key={`${item.alt}_${index}`}
                src={item.src}
                alt={item.alt}
                describe={item.describe}
              />
            ))}
          </ProcessContent>
        </ProcessContainer>
      </AdvantageSection>
      <VideoSection>
        <Topic color="#fafafa">{t("home.video")}</Topic>
        <Divider />
        <VideoContent>
          <Swipe>
            {collections
              .filter((item, index) => index < 4)
              .map((item, index) => (
                <VideoCard
                  key={`${item.name}_${index}`}
                  name={item.name}
                  url={item.url}
                  width={isPhone ? "280px" : "400px"}
                  height={isPhone ? "210px" : "300px"}
                  light={true}
                />
              ))}
          </Swipe>
        </VideoContent>
      </VideoSection>
      <GallerySection>
        <ImageGallery
          items={getImages()}
          lazyLoad={true}
          autoPlay={true}
          showPlayButton={false}
          showNav={false}
          showFullscreenButton={false}
        />
      </GallerySection>
    </Fragment>
  );
};
export default HomePage;
