import React, { useEffect, Fragment, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Nav from "./components/Nav";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import TechnoPage from "./pages/TechnoPage";
import AboutPage from "./pages/AboutPage";
import ProductPage from "./pages/ProductPage";
import GlobalStyle from "./styles/GlobalSheet";

import "react-medium-image-zoom/dist/styles.css";
import "react-image-gallery/styles/css/image-gallery.css";

const App = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <Fragment key='app'>
      <Suspense fallback={null}>
        <Nav />
        <Switch>
          <Route exact path='/' component={HomePage} />
          <Route path='/products/:productId' component={ProductPage} />
          <Route path='/products'>
            <Redirect to='/products/as' />
          </Route>
          <Route path='/technology' component={TechnoPage} />
          <Route path='/about' component={AboutPage} />
        </Switch>
        <Footer />
      </Suspense>
      <GlobalStyle />
    </Fragment>
  );
};

export default App;
