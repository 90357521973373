import styled, { css } from "styled-components";
import { mediaPhone } from "../../styles/media";
import { spaces, fontSizes, fontWeight, otherColor } from "../../styles/mixins";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Region = styled.div`
  display: flex;
  font-size: ${fontSizes.large5};
  font-weight: ${fontWeight.semiBold};
  color: ${otherColor.primary};
  align-items: center;
  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 2px solid ${otherColor.primary};
  }

  &::before {
    margin-right: ${spaces.large2};
  }
  &::after {
    margin-left: ${spaces.large2};
  }
  ${mediaPhone(css`
    font-size: ${fontSizes.large2};
  `)}
`;

export const CountriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: ${spaces.large4} ${spaces.large2};
`;

export const CountryWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: ${spaces.large4};
  }
`;

export const CountryTitle = styled.h2`
  font-size: ${fontSizes.large4};
  font-weight: ${fontWeight.semiBold};
  opacity: 0.7;
  ${mediaPhone(css`
    font-size: ${fontSizes.large2};
  `)}
`;
