export const data = [
  {
    title: "techonology.solgel",
    info: "techonology.solgel.content",
  },
  {
    title: "techonology.emulsion",
    info: "techonology.emulsion.content",
  },
  {
    title: "techonology.organic",
    info: "techonology.organic.content",
  },
  {
    title: "techonology.formulation",
    info: "techonology.formulation.content",
  },
  {
    title: "techonology.hybrid",
    info: "techonology.hybrid.content",
  },
];