import styled, { css } from "styled-components";
import { spaces, otherColor, fontSizes } from "../../styles/mixins";
import { mediaPhone } from "../../styles/media";
import { VscCircleOutline } from "react-icons/vsc";
import { FcCheckmark } from "react-icons/fc";

export const Header = styled.div`
  width: 100%;
  text-align: left;
`;

export const SubContent = styled.p`
  opacity: 0.7;
  margin-bottom: ${spaces.normal};
`;

export const DetailContainer = styled.div`
  width: 100%;
  padding: ${spaces.large4};
  padding-bottom: 0;
  display: flex;
  ${mediaPhone(css`
    flex-direction: column;
    padding: ${spaces.normal};
  `)}
`;

export const Detail = styled.div`
  width: 100%;
  padding: ${spaces.large4};
  padding-bottom: 0;
  ${mediaPhone(css`
    padding: 0;
  `)}
`;

export const EachTopicContainer = styled.div`
  margin-bottom: ${spaces.large4};
  ${mediaPhone(css`
    margin-bottom: ${spaces.large2};
  `)}
`;

export const Topic = styled.h2`
  font-size: ${fontSizes.large3};
  padding-left: ${spaces.normal};
  border-left: 5px solid ${otherColor.primary};
  transition: all 0.2s ease-in-out;
  ${mediaPhone(css`
    font-size: ${fontSizes.large1};
  `)}
  &:hover {
    border-left: 15px solid ${otherColor.primary};
  }
`;

export const Content = styled.p`
  font-size: ${fontSizes.normal};
  padding: ${spaces.large2};
  word-wrap: break-word;
  ${mediaPhone(css`
    font-size: ${fontSizes.small1};
    padding: ${spaces.normal} ${spaces.large2};
  `)}
`;

export const RecommedListItem = styled.li`
  list-style: none;
  padding: ${spaces.normal} ${spaces.large2};
  word-wrap: break-word;
  display:flex;
  ${mediaPhone(css`
    font-size: ${fontSizes.small1};
    padding: ${spaces.normal};
  `)}
`;

export const ProductListContainer = styled.div`
  padding: 0 ${spaces.large6};
  ${mediaPhone(css`
    padding: 0 ${spaces.normal};
  `)}
`;

export const ProductItem = styled.li`
  list-style: none;
  padding: ${spaces.normal} ${spaces.large2};
  display: flex;
`;

export const ListContainer = styled.ul`
  padding-top: ${spaces.large1};
`;

export const CircleList = styled(VscCircleOutline)`
  color: ${otherColor.primary};
  margin-right: ${spaces.normal}; 
`;

export const CheckList = styled(FcCheckmark)`
  font-size: ${fontSizes.large1};
  margin-right: ${spaces.normal};
`;
