import { Fragment,useState } from "react";
import Slider from "react-slick";
import { Dots, DotContainer } from "./styled";

const Swipe = ({ children }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          nextArrow: <Fragment/>,
          prevArrow: <Fragment/>,
        },
      },
    ],
  };
  return (
    <Fragment>
      <Slider
        {...settings}
        appendDots={(dots) => <DotContainer>{dots}</DotContainer>}
        customPaging={(i) => <Dots $isActive={i===activeSlide}></Dots>}
        afterChange={(current) => setActiveSlide(current)}
      >
        {children}
      </Slider>
    </Fragment>
  );
};
export default Swipe;
