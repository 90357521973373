import styled from "styled-components";
import Menu, { SubMenu, MenuItem } from "rc-menu";
import { otherColor } from "../../styles/mixins";

export const MyMenu = styled(Menu)`
  background-color: ${otherColor.primary} !important;
  color: #fff !important;
  cursor: pointer;
`;

export const MyMenuItem = styled(MenuItem)`
  background-color: ${otherColor.primary} !important;
  color: #fff !important;
  cursor: pointer;
  &:hover {
    background-color: ${otherColor.secondary} !important;
  }
`;

export const MySubMenuItem = styled(SubMenu)`
  background-color: ${otherColor.primary} !important;
  color: #fff !important;
  cursor: pointer;
`;
