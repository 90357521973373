export const awards = [
  {
    medium: "https://i.ibb.co/17KLvnB/certify1.jpg",
    small: "https://i.ibb.co/3sHmTpZ/certify1-small.jpg",
    alt: "certificate-1",
  },
  {
    medium: "https://i.ibb.co/t4H8nK4/certify2.jpg",
    small: "https://i.ibb.co/DrWzJrt/certify2-small.jpg",
    alt: "certificate-2",
  },
];
