import { useTranslation } from "react-i18next";

import { timeline } from "../../data/history";

import {
  TimelineContainer,
  ContentContainer,
  Right,
  Content,
  Header,
  Event,
} from "./styled";
const Timeline = () => {
  const { t } = useTranslation();

  return (
    <TimelineContainer>
      {timeline.map((item,index) => (
        <ContentContainer key={`${item.date}_${index}`}>
          <Right>
            <Content>
              <Header>{t(item.date)}</Header>
              <br></br>
              <Event>{t(item.event)}</Event>
            </Content>
          </Right>
        </ContentContainer>
      ))}
    </TimelineContainer>
  );
};
export default Timeline;
