export const grid = {
  gutterWidth: 16,
};

export const lineHeights = {
  large5: "44px",
  large4: "40px",
  large3: "36px",
  large2: "32px",
  large1: "28px",
  normal: "24px",
  small1: "20px",
  small2: "16px",
  small3: "14px",
};

export const iconSizes = {
  extraLarge2: "295px",
  extraLarge1: "205px",
  large3: "128px",
  large2: "64px",
  large1: "32px",
  medium: "24px",
  normal: "16px",
};

export const spaces = {
  large12: "200px",
  large11: "160px",
  large10: "144px",
  large9: "128px",
  large8: "96px",
  large7: "80px",
  large6: "64px",
  large5: "48px",
  large4: "32px",
  large3: "24px",
  large2: "16px",
  large1: "12px",
  normal: "8px",
  small1: "4px",
  small2: "2px",
  small3: "1px",
};

export const borders = {
  default: "4px",
  extraRound: "16px",
  circle: "50%",
  round: "12px",
};
