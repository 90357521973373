export const fontSizes = {
  large10: "7.2rem",
  large9: "6.4rem",
  large8: "4.8rem",
  large7: "4.0rem",
  large6: "3.6rem",
  large5: "3.2rem",
  large4: "2.8rem",
  large3: "2.4rem",
  large2: "2.0rem",
  large1: "1.8rem",
  normal: "1.6rem",
  small1: "1.4rem",
  small2: "1.2rem",
  small3: "1.0rem",
  small4: ".8rem",
};

export const fontWeight = {
  thin: "100",
  light: "300",
  regular: "400",
  medium: "500",
  semiBold: "600",
  bold: "700",
  black: "900",
};
